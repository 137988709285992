import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Context, { IUser } from './Context';
import { localDecrypt, localEncrypt } from '../../utils/crypto';
import { REEM_USER_STORAGE } from '../../utils/constants';
import RequestReem from '../../services/RequestReem';

interface IUserProvider {
  children: ReactNode;
}

export default ({ children }: IUserProvider) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userTemp = localStorage.getItem(REEM_USER_STORAGE);
    if (userTemp) {
      const decrypt = localDecrypt(userTemp);
      setLoading(true);
      RequestReem({
        context: 'private',
        request: 'me',
      })
        .then(() => {
          setUser(JSON.parse(decrypt));
        })
        .catch(() => {
          localStorage.removeItem(REEM_USER_STORAGE);
          navigate('/');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const userEncrypt = localEncrypt(JSON.stringify(user));
      localStorage.setItem(REEM_USER_STORAGE, userEncrypt);
    }
  }, [user]);

  const value = useMemo(
    () => ({
      loading,
      user: {
        name: user?.name,
        accessToken: user?.accessToken,
      },
      setUser: (userTemp: IUser) => {
        setUser(userTemp);
      },
    }),
    [user, loading],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
