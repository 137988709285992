import { MdAccountBox, MdSettings } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import SlideButton from '../components/SlideButton';
import stg from '../../../utils/stg';

export default () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full">
      <h2 className="px-2 font-bold text-base text-white bg-gray-700 py-3 text-center">
        REEM
      </h2>
      <SlideButton
        icon={<MdAccountBox className="w-5 h-5" />}
        text={stg('my_account')}
        onClick={() => {
          navigate('/account');
        }}
      />
      <SlideButton
        icon={<MdSettings className="w-5 h-5" />}
        text={stg('configuration')}
        onClick={() => {
          navigate('settings');
        }}
      />
    </div>
  );
};
