import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputReem from '../../components/InputReem';
import stg from '../../utils/stg';
import RequestReem from '../../services/RequestReem';
import { decrypt, encrypt } from '../../utils/crypto';
import ButtonReem from '../../components/ButtonReem';
import { DeviceContext, UserContext } from '../../repo';
import logo from '../../assets/logo2_silueta.png';

interface loginForm {
  correo: string;
  password: string;
  error?: string;
  loading: boolean;
}

const Login = () => {
  const { setUser } = useContext(UserContext);
  const { device } = useContext(DeviceContext);
  const navigate = useNavigate();
  const [loginProps, setLoginProps] = useState<loginForm>({
    correo: '',
    password: '',
    error: undefined,
    loading: false,
  });

  const { correo, loading, password, error } = loginProps;

  const fieldsFilled = correo && password;
  const disabledSignIn = !fieldsFilled || loading;

  const handlerLogin = () => {
    if (!fieldsFilled) return;
    setLoginProps({ ...loginProps, loading: true, error: undefined });
    RequestReem({
      request: 'login',
      variables: {
        input: {
          email: correo,
          password: encrypt(password),
          identificador: device?.identificador,
        },
      },
    })
      .then((data) => {
        const { refresh_token, nombre } = data.login;
        const publicMessage = decrypt(refresh_token) as any;
        const accessToken = publicMessage?.data?.access_token;
        setUser?.({
          name: nombre,
          accessToken,
        });
        navigate('account');
      })
      .catch((e) => {
        if (['Conexión no segura'].includes(e.message)) {
          setLoginProps({
            ...loginProps,
            loading: false,
            error: stg('something_went_wrong'),
          });
        } else {
          setLoginProps({
            ...loginProps,
            loading: false,
            error: stg('review_your_data'),
          });
        }
      });
  };

  return (
    <div className="w-full h-full flex flex-col p-4">
      <img src={logo} alt="logo-reem" className="size-24 mx-auto" />
      <h1 className="w-full text-center text-4xl font-semibold">Reem</h1>
      <h3 className="w-full text-center text-lg font-normal text-gray-400 mt-6">
        {stg('welcome_to_reem_system')}
      </h3>
      <InputReem
        label={stg('email')}
        type="email"
        placeholder={stg('email_placeholder')}
        className="mt-8"
        onChange={(e) =>
          setLoginProps({ ...loginProps, correo: e.target.value })
        }
      />
      <InputReem
        label={stg('password')}
        type="password"
        placeholder={stg('password')}
        className="mt-2"
        onChange={(e) =>
          setLoginProps({ ...loginProps, password: e.target.value })
        }
      />
      {loginProps.error && (
        <div className="fade-in flex flex-col mt-2 bg-red-500 rounded-lg p-3">
          <p className="text-white text-base font-medium">{error}</p>
        </div>
      )}
      <ButtonReem
        text={stg('login')}
        loading={loading}
        disabled={disabledSignIn}
        handlerClick={handlerLogin}
        className="mt-auto"
      />
    </div>
  );
};

export default Login;
