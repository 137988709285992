const stg = {
  welcome_to_reem_system: 'Bienvenido al sistema de cuenta de Reem',
  register_my_self: 'Registrarme',
  login: 'Ingresar',
  email: 'Correo',
  password: 'Contraseña',
  email_placeholder: 'Correo electrónico',
  review_your_data: 'Revisa tus datos',
  something_went_wrong: 'Algo salió mal, intenta mas tarde.',
  my_account: 'Mi cuenta',
  configuration: 'Configuración',
  name: 'Nombre',
  account_information: 'Información de la cuenta',
  delete_account: 'Eliminar cuenta',
  do_you_want_to_delete_this_account: 'Desea eliminar esta cuenta?',
  this_decision_is_irreversible: 'esta decisión es irreversible',
  code: 'Código',
  confirm_delete_account: 'Confirmar eliminación de cuenta',
  code_c: 'Código: %c',
  confirm_code: 'Confirmar código',
};

type stgType = keyof typeof stg;

interface IOptions {
  replace: { [key: string]: string };
}

export default (key: stgType, options?: IOptions) => {
  if (options && options.replace) {
    let str = stg[key];
    const keysToReplace = Object.keys(options.replace);
    keysToReplace.forEach((keyToReplace) => {
      str = str.replace(keyToReplace, options.replace[keyToReplace]);
    });
    return str;
  }
  return stg[key];
};
