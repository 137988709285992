import { ReactNode } from 'react';

interface ISlideButton {
  icon: ReactNode;
  text: string;
  onClick: () => void;
}

export default ({ icon, onClick, text }: ISlideButton) => (
  <div
    className="flex flex-row w-full text-white hover:bg-gray-500 cursor-pointer py-3 px-2 select-none"
    aria-hidden
    onClick={onClick}
  >
    {icon}
    <p className="font-semibold text-sm ml-2">{text}</p>
  </div>
);
