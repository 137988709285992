export default () => (
  <div className="flex flex-col p-4 gap-y-4 overflow-y-auto h-full">
    <h2 className="font-bold text-lg">
      Política de Privacidad: Plataforma de Intermediación Empresarial Reem
      Central
    </h2>
    <h3 className="font-medium text-xs text-gray-700">
      Fecha de entrada en vigencia: 1 - Ene - 2024
    </h3>
    <p className="font-normal text-base">
      Agradecemos tu interés en nuestra plataforma de intermediación
      empresarial, Reem Central. Queremos informarte sobre nuestra política de
      privacidad y cómo manejamos la información personal que nos proporcionas.
    </p>

    <h4 className="font-semibold text-base">1. Información Recopilada:</h4>
    <p className="font-normal text-base">
      Recopilamos la información que nos proporcionas voluntariamente,
      incluyendo tu nombre, correo electrónico y ciudad. Esta información es
      esencial para facilitar la conexión entre empresas independientes que
      ofrecen servicios de envío de mercancía y establecimientos interesados en
      dichos servicios.
    </p>

    <h4 className="font-semibold text-base">2. Uso de la Información:</h4>
    <p className="font-normal text-base">
      La información recopilada tiene el único propósito de facilitar la
      comunicación entre las partes interesadas. Actuamos como intermediarios
      para conectar empresas independientes de servicios de envío con
      establecimientos comerciales. No utilizamos tus datos personales para
      realizar operaciones directas ni nos responsabilizamos por el uso que las
      partes puedan dar a la información intercambiada.
    </p>
    <h4 className="font-semibold text-base">3. Comunicación y Divulgación:</h4>
    <p className="font-normal text-base">
      Nos comprometemos a no vender, alquilar ni compartir tu información
      personal con terceros no relacionados con la finalidad de esta plataforma.
      La información se comparte únicamente entre las partes interesadas en
      establecer contactos comerciales.
    </p>
    <h4 className="font-semibold text-base">4. Responsabilidad Limitada:</h4>
    <p className="font-normal text-base">
      Queremos destacar que actuamos únicamente como intermediarios y no somos
      responsables por el uso que las empresas independientes y los
      establecimientos comerciales den a la información intercambiada.
      Recomendamos a ambas partes ejercer la debida diligencia antes de
      comprometerse en cualquier transacción comercial.
    </p>
    <h4 className="font-semibold text-base">
      5. Mejora Continua de la Infraestructura:
    </h4>
    <p className="font-normal text-base">
      Trabajamos constantemente en mejorar la seguridad de la plataforma y
      actualizamos nuestras medidas regularmente.
    </p>
    <h4 className="font-semibold text-base">6. Consentimiento:</h4>
    <p className="font-normal text-base">
      Al utilizar nuestra plataforma, aceptas los términos de esta política de
      privacidad. Si no estás de acuerdo con estos términos, te solicitamos que
      no proporcionas tu información personal.
    </p>
    <h4 className="font-semibold text-base">7. Cambios en la Política:</h4>
    <p className="font-normal text-base">
      Nos reservamos el derecho de realizar cambios en esta política en
      cualquier momento. Te recomendamos revisar periódicamente esta página para
      estar al tanto de posibles actualizaciones.
    </p>
    <h4 className="font-semibold text-base">8. Eliminación de Datos:</h4>
    <p className="font-normal text-base">
      En caso de necesitar eliminar tus datos, puedes acceder a
      <a
        className="text-blue-700 font-bold mx-1"
        href="https://auth.reem-co.app/account"
      >
        Sistema de gestion de cuentas Reem
      </a>
      , dirigirte a la sección de Settings y eliminar tu cuenta.
    </p>
    <p className="font-normal text-base">
      Gracias por confiar en Reem Central, tu plataforma de intermediación
      empresarial. Atentamente,
    </p>
    <p className="font-bold text-base"> Reem Team</p>
  </div>
);
