import { ReactNode, useEffect, useMemo, useState } from 'react';
import DeviceContext, { IDeviceState } from './Context';
import { decrypt, localDecrypt, localEncrypt } from '../../utils/crypto';
import { REEM_DEVICE_ID, REEM_DEVICE_STORAGE } from '../../utils/constants';
import RequestReem from '../../services/RequestReem';

interface IDeviceProvider {
  children: ReactNode;
}

export default ({ children }: IDeviceProvider) => {
  const [device, setDevice] = useState<IDeviceState | undefined>(undefined);

  useEffect(() => {
    if (device) {
      const deviceEncrypt = localEncrypt(JSON.stringify(device));
      localStorage.setItem(REEM_DEVICE_STORAGE, deviceEncrypt);
    }
  }, [device]);

  useEffect(() => {
    const deviceStorage = localStorage.getItem(REEM_DEVICE_STORAGE);
    if (deviceStorage) {
      const decryptTemp = localDecrypt(deviceStorage);
      setDevice(JSON.parse(decryptTemp));
    } else {
      RequestReem({
        request: 'identifyme',
        variables: {
          input: {
            versionApp: '0.1.0',
            dispositivo: JSON.stringify({
              device: navigator.userAgent,
            }),
            app_id: REEM_DEVICE_ID,
          },
        },
      }).then((data) => {
        const { identifyMe } = data;
        const identifyDecrypt = decrypt(identifyMe) as any;
        setDevice({ identificador: identifyDecrypt.data.msg });
      });
    }
  }, []);

  const value = useMemo(
    () => ({
      device,
      setDevice: (newDevice: IDeviceState) => {
        setDevice(newDevice);
      },
    }),
    [device],
  );

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};
