export default `
    query login($input:EmailLogin!){
        login(input:$input){
            nombre
            cuentas{
                label
                url
                type
            }
            refresh_token
        }
    }
`;
