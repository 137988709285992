import { REEM_USER_STORAGE } from '../utils/constants';
import { localDecrypt } from '../utils/crypto';
import graphql from './graphql';

type TRequest = keyof typeof graphql;

interface IFetchReem {
  context?: 'public' | 'private';
  request: TRequest;
  variables?: {
    input: any;
  };
}

interface IHeaders {
  'Content-Type': string;
  Authorization?: string;
}

export default ({ context = 'public', request, variables }: IFetchReem) => {
  const authEndpoint = process.env.REACT_APP_AUTH_URL;
  const localhost = 'http://localhost:3000';

  const body = {
    operationName: request,
    query: graphql[request],
    variables,
  };

  const headers: IHeaders = {
    'Content-Type': 'application/json',
  };
  if (context === 'private') {
    const userAuthEncrypted = localStorage.getItem(REEM_USER_STORAGE) || '';
    const userDecrypt = localDecrypt(userAuthEncrypted);
    const user = JSON.parse(userDecrypt) || {};
    headers.Authorization = user?.accessToken;
  }

  return fetch(authEndpoint || localhost, {
    headers: { ...headers },
    method: 'POST',
    body: JSON.stringify(body),
  }).then(async (r) => {
    const json = await r.json();
    if ('errors' in json) {
      const errors = json?.errors || [];
      if (errors?.length > 0) {
        const error = errors[0];
        const message = error?.message;
        throw new Error(message);
      }
    }

    return json.data;
  });
};
