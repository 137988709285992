interface ITextLabel {
  label: string;
  value: string;
}

export default ({ label, value }: ITextLabel) => (
  <div className="flex flex-col">
    <p className="text-gray-900 text-sm font-medium">{label}</p>
    <p className="text-gray-500 text-base font-semibold">{value}</p>
  </div>
);
