export default `
    query me{
        me{
            nombre
            cuentas{
                label
                url
                type
            }
            refresh_token
        }
    }
`;
