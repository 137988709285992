import Gray4Border4 from './Gray4Border4';
import WhiteBorder4 from './WhiteBorder4 ';

interface ILoadingSpinner {
  className?: string;
  color?: 'gray4' | 'white';
}

export default ({ className = '', color = 'gray4' }: ILoadingSpinner) => (
  <div className={`relative h-6 w-6 ${className}`}>
    {
      {
        gray4: <Gray4Border4 />,
        white: <WhiteBorder4 />,
      }[color]
    }
  </div>
);
