import { useContext } from 'react';
import stg from '../../../utils/stg';
import TextLabel from '../components/TextLabel';
import { UserContext } from '../../../repo';

// eslint-disable-next-line no-unused-vars
const mock = {
  name: 'Reem Test',
  email: 'test-reem@yopmail.com',
};

export default () => {
  const { user } = useContext(UserContext);
  return (
    <div className="flex flex-col gap-y-1 px-4 py-6">
      <p className="text-lg font-bold text-gray-900">
        {stg('account_information')}
      </p>
      <TextLabel label={stg('name')} value={user?.name!} />
    </div>
  );
};
