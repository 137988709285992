import LoadingSpinner from './LoadingSpinner';

type colorType = 'blue' | 'gray' | 'red';

interface IButtonReem {
  disabled?: boolean;
  handlerClick?(): void;
  loading?: boolean;
  text: string;
  width?: string;
  color?: colorType;
  className?: string;
}

const styles = {
  blue: 'bg-blue-400 hover:bg-blue-500',
  gray: 'bg-gray-400 hover:bg-gray-500',
  red: 'bg-red-400 hover:bg-red-500',
};

export default ({
  text,
  disabled = false,
  handlerClick,
  loading,
  width = '100%',
  color = 'blue',
  className = '',
}: IButtonReem) => (
  <button
    type="button"
    className={`w-full py-2 text-white ${
      disabled ? styles.gray : styles[color]
    } rounded-lg ${className}`}
    onClick={handlerClick}
    disabled={disabled}
    style={{ maxWidth: width }}
  >
    {loading ? <LoadingSpinner className="mx-auto" color="white" /> : text}
  </button>
);
