import { Route, Routes } from 'react-router-dom';
import AccountInformation from './layers/AccountInformation';
import Settings from './layers/Settings';

import SlideMenu from './layers/SlideMenu';

export default () => (
  <div className="flex flex-row w-full h-full overflow-hidden">
    <div className="flex flex-col w-40 bg-gray-400 rounded-l-lg">
      <SlideMenu />
    </div>
    <div className="flex flex-auto">
      <Routes>
        <Route path="*" Component={AccountInformation} />
        <Route path="settings" Component={Settings} />
      </Routes>
    </div>
  </div>
);
