import jwtEncode from 'jwt-encode';
import { jwtDecode } from 'jwt-decode';
import Crypto from 'crypto-js';

const encrypt = (msg: string) => {
  const publicKey = process.env.REACT_APP_PUBLIC_MESSAGE || 'testing';
  return jwtEncode({ data: { msg } }, publicKey);
};

const decrypt = (msg: string) => jwtDecode(msg);

const localEncrypt = (msg: string) => {
  const cryptoKey = process.env.REACT_APP_CRYPTO_KEY || 'testing';

  return Crypto.AES.encrypt(msg, cryptoKey).toString();
};

const localDecrypt = (msg: string) => {
  const cryptoKey = process.env.REACT_APP_CRYPTO_KEY || 'testing';
  return Crypto.AES.decrypt(msg, cryptoKey).toString(Crypto.enc.Utf8);
};

export { encrypt, decrypt, localEncrypt, localDecrypt };
