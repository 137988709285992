import login from './login';
import unregister from './unregister';
import identifyme from './identifyme';
import me from './me';

export default {
  login,
  unregister,
  identifyme,
  me,
};
