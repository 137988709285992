import { ChangeEvent, HTMLInputTypeAttribute, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

interface InputReemProps {
  className?: string;
  label: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  // eslint-disable-next-line no-unused-vars
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  width?: string;
}

const InputReem = ({
  label,
  onChange,
  className,
  type,
  placeholder,
  width = '100%',
}: InputReemProps) => {
  const [uncrypt, setUncrypt] = useState(false);

  return (
    <div
      className={`overflow-hidden w-full flex flex-col relative ${className}`}
      style={{ maxWidth: width }}
    >
      <p className="text-base text-gray-700 font-semibold">{label}</p>
      <div className="relative w-full">
        <input
          type={!uncrypt ? type : 'text'}
          className={`border rounded-lg border-gray-700 p-2 w-full ${
            uncrypt ? 'pr-9' : ''
          }`}
          placeholder={placeholder}
          onChange={onChange}
        />
        {type === 'password' && (
          <div
            aria-hidden
            className="absolute px-2 right-0 top-0 h-full flex justify-center items-center"
            onClick={() => setUncrypt(!uncrypt)}
          >
            {uncrypt ? (
              <AiFillEye size={20} />
            ) : (
              <AiFillEyeInvisible size={20} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

InputReem.defaultProps = {
  className: '',
  type: 'text',
  placeholder: '',
};

export default InputReem;
