import { useState } from 'react';
import ButtonReem from '../../../components/ButtonReem';
import stg from '../../../utils/stg';
import InputReem from '../../../components/InputReem';
import RequestReem from '../../../services/RequestReem';
import { REEM_USER_STORAGE } from '../../../utils/constants';

interface ICodeState {
  code: string;
  input: string;
}

export default () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<ICodeState>({
    code: '',
    input: '',
  });

  const handlerUnregister = () => {
    setLoading(true);
    RequestReem({
      request: 'unregister',
      context: 'private',
    })
      .then(() => {
        localStorage.removeItem(REEM_USER_STORAGE);
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlerOpenCode = () => {
    let newCode = '';
    for (let i = 0; i < 8; i += 1) {
      const ascii = Math.random() * (90 - 65) + 65;
      newCode += String.fromCharCode(ascii);
    }

    setCode({ ...code, code: newCode });
  };

  return (
    <div className="flex flex-col w-full px-4 py-6">
      <p className="text-base font-semibold text-red-700">
        {stg('do_you_want_to_delete_this_account')}
      </p>
      <p className="text-sm font-normal text-gray-600">
        {stg('this_decision_is_irreversible')}
      </p>

      <div
        className="self-start flex flex-col flexible-animation overflow-hidden bg-gray-200 rounded-lg px-4"
        style={{
          maxHeight: code.code ? '900px' : '0px',
          margin: code.code ? '10px 0px' : '0px',
        }}
      >
        <p className="font-bold text-base text-gray-800 mt-4 select-none">
          {stg('code_c', { replace: { '%c': code.code } })}
        </p>
        <InputReem
          width="200px"
          className="mt-2"
          placeholder="Ej: ABCD"
          label={stg('confirm_code')}
          onChange={(e) => {
            setCode({ ...code, input: e.currentTarget.value });
          }}
        />
        <ButtonReem
          text={stg('confirm_delete_account')}
          width="200px"
          color="red"
          className="mt-5 mb-4"
          disabled={code.input !== code.code}
          handlerClick={handlerUnregister}
          loading={loading}
        />
      </div>
      {!code.code && (
        <ButtonReem
          text={stg('delete_account')}
          width="200px"
          color="red"
          className="mt-5"
          handlerClick={handlerOpenCode}
        />
      )}
    </div>
  );
};
