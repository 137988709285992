import { Navigate } from 'react-router-dom';
import { AccountInfo } from '../pages';

export default [
  {
    path: 'account/*',
    Component: AccountInfo,
  },
  {
    path: '*',
    Component: () => <Navigate to="/account" />,
  },
];
