import { createContext } from 'react';

export interface IUser {
  name?: string;
  accessToken?: string;
}

interface IUserProvider {
  loading: boolean;
  user: IUser;
  // eslint-disable-next-line no-unused-vars
  setUser?: (user: IUser) => void;
}

const user: IUserProvider = {
  loading: false,
  user: {
    name: undefined,
    accessToken: undefined,
  },
  setUser: undefined,
};

const context = createContext(user);

export default context;
