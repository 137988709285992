import { createContext } from 'react';

export interface IDeviceState {
  identificador?: string;
}

interface IDeviceContext {
  device?: IDeviceState;
  // eslint-disable-next-line no-unused-vars
  setDevice?: (device: IDeviceState) => void;
}

const DeviceDefault: IDeviceContext = {
  device: undefined,
  setDevice: undefined,
};

const DeviceContext = createContext(DeviceDefault);

export default DeviceContext;
