import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserContext } from './repo';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import LoadingSpinner from './components/LoadingSpinner';
import PoliciesRoutes from './routes/PoliciesRoutes';

const App = () => {
  const { user, loading } = useContext(UserContext);

  const genRoutes = user.accessToken ? PrivateRoutes : PublicRoutes;

  const divSettings = user.accessToken
    ? {
        width: '600px',
        height: '400px',
      }
    : {
        width: '384px',
        height: '550px',
      };

  return (
    <div className="w-full h-full bg-gray-100 flex flex-col items-center justify-center">
      <div
        className="bg-white rounded-lg overflow-hidden flexible-animation shadow-lg"
        style={divSettings}
      >
        {loading ? (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <Routes>
            {[...PoliciesRoutes, ...genRoutes].map((route) => (
              <Route
                key={route.path}
                path={route.path}
                Component={route.Component}
              />
            ))}
          </Routes>
        )}
      </div>
    </div>
  );
};

export default App;
